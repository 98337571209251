/* 
    Created on : 21 juine 2022, 18:09:24
    Author     : Stéphane Ramé <stephane.rame@dev-system.com>
*/

/** Bootstrap 5 */
@import "~bootstrap/scss/bootstrap";

/** Fontawesome */
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

.container {
    max-width: 950px;
}

body {
    background: #ffffff url('../img/fond.png') no-repeat center 140px;
    font-size: 16px;
    color: #555555
}

.boxTitre {
    width: 100%;
    margin: 0 auto;
    padding: 0 5px;
    line-height: 25px;
    color: #6f6e70;
    font-size: 200%;
    font-weight: normal;
    border: 1px solid rgba(0,0,0,0.1);
    border-bottom: 0px;
    background: #eeeeee;
    line-height: 1.2em
}

/** Vidéos Iframe */
.container-video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
